import styled from "styled-components";
import { Plus } from "phosphor-react";

const ItemDiv = styled.div`
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
    cursor: pointer;
    user-select: none;
    color: #DCDCDC;
`

const PlusDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: color;
    background-color: #ECF3FE;
    padding: 12px;
    border-radius: 100%;
`

function Item(props) {
    return (
        <ItemDiv>
            <PlusDiv><Plus size={24} color="#3359FC" /></PlusDiv>
            추가하기
        </ItemDiv>
    )
}

export default Item