import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes, Route } from "react-router-dom";

import StatusMain from "./status/main"
import StatusAdd from "./status/add"
import StatusEdit from "./status/edit"

function ServerStatus() {
    const navigate = useNavigate()
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/login")
        }
    }, [navigate])


    return (
        <>
            <Routes>
                <Route path="/" element={<StatusMain />} />
                <Route path="/add" element={<StatusAdd />} />
                <Route path="/edit/*" element={<StatusEdit />} />
            </Routes>
        </>
    );
}

export default ServerStatus;
