import { useEffect, useState } from 'react';
import styled from 'styled-components'
import { Package, CaretRight, File  } from "phosphor-react";
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import StatusDot from "../components/StatusDot"
import Item from "../components/item"

import dayjs from 'dayjs';
import 'dayjs/locale/ko';
dayjs.locale('ko')

const Wrapper = styled.div`
  background-color: #ECEFF2;
`

const Divver = styled.div`
  background-color: #ECEFF2;
  padding: 20px;
  min-height: 100vh;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 700;
  color: #3359FC;
  margin-bottom: 16px;
`

const Revenue = styled.div`
  background-color: #ffffff;
  border-radius: 14px;
  padding: 16px 24px;
  text-align: center;
  color: #000000;
  font-weight: 800;

  span {
    font-weight: 400;
    font-size: 32px;
    font-family: 'Oswald', sans-serif;
  }
`

const BoxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, auto));
  gap: 8px;
`

const BoxLine = styled(Link)`
  background-color: #ffffff;
  border-radius: 14px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: #000000;
  text-decoration: none;
  -webkit-tap-highlight-color : transparent;
  transition: 200ms;

  &:active {
    background-color: #f5f8fa;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 8px 8px rgba(0,0,0,0.05);
  }
`

const BoxLineA = styled.a`
  background-color: #ffffff;
  border-radius: 14px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: #000000;
  text-decoration: none;
  -webkit-tap-highlight-color : transparent;
  transition: 200ms;

  &:active {
    background-color: #f5f8fa;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 8px 8px rgba(0,0,0,0.05);
  }
`

const BoxLineText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Box = styled(Link)`
  background-color: #ffffff;
  border-radius: 14px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: #000000;
  text-decoration: none;
  flex-direction: column;
`

const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const BoxTitleText = styled.div`
  font-size: 16px;
`

const Logout = styled.div`
  margin-top: 16px;
  text-align: center;
  color: #3359FC;
  font-size: 12px;
  cursor: pointer;
`

const ServerStatus = (props) => {
  const [status, setStatus] = useState({code: "waiting", text: "잠시만 기다려주세요"})

  useEffect(() => {
    let isOK = true
    for (let i of props.server) {
      if (i.svStatus !== "good") isOK = false
    }
    setStatus(isOK ? {code: "good", text: "서버가 모두 정상이에요"} : {code: "bad", text: "서버에 문제가 발생했어요"})
  }, [props.server])

  return (
    <BoxLine to="/status">
      <BoxLineText>
        <StatusDot status={status.code} />
        {status.text}
      </BoxLineText>
      <CaretRight size={16} />
    </BoxLine>
  )
}

const ServerMessage = (props) => {
  const [messages, setMessages] = useState([
    {
        "_id": "61cd4ea2cc41c20225476536",
        "msgContent": "Loading",
        "msgAuthorId": "Loading",
        "msgLevel": "info",
        "msgCreated": "2021-12-30T06:16:02.942Z",
    },
    {
        "_id": "61cd4e30cc41c20225476533",
        "msgContent": "Loading",
        "msgAuthorId": "Loading",
        "msgLevel": "info",
        "msgCreated": "2021-12-30T06:14:08.119Z",
    },
    {
        "_id": "61cd4dbfa894f46b74bfc1c2",
        "msgContent": "Loading",
        "msgAuthorId": "Loading",
        "msgLevel": "info",
        "msgCreated": "2021-12-30T06:12:15.708Z",
    }
  ])

  useEffect(() => {
    try {
      (async () => {
        const response = await axios.get("https://api.hyuns.dev/message?limit=3", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        setMessages(response.data.data)
      })()
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    return () => setMessages([]);
  }, []);

  return (
    <Box to="/message">
      <BoxTitle>
        <BoxTitleText>최근 메세지</BoxTitleText>
        <CaretRight size={16} />
      </BoxTitle>
      {
        messages.map((e,i) => {
          let img, name
          for (let sv of props.server) {
            if (sv.svId === e.msgAuthorId) {
              img = sv.svImg
              name = sv.svName
            }
          }

          return <Item key={i} img={img} status={e.msgLevel} label={`[${name || "삭제된 서버"}] ${dayjs(e.msgCreated).format("YY/MM/DD HH:mm:ss")}`} content={e.msgContent} />
        })
      }
    </Box>
  )
}

const VarSecret = (props) => {
  return (
    <BoxLine to="/var/secret">
      <BoxLineText>
        비밀변수 확인하기
      </BoxLineText>
      <CaretRight size={16} />
    </BoxLine>
  )
}

const VarGlobal = (props) => {
  return (
    <BoxLine to="/var/global">
      <BoxLineText>
        공개변수 확인하기
      </BoxLineText>
      <CaretRight size={16} />
    </BoxLine>
  )
}

const Resource = (props) => {
  return (
    <BoxLine to="/resource">
      <BoxLineText>
        리소스 관리하기
      </BoxLineText>
      <CaretRight size={16} />
    </BoxLine>
  )
}

const ApiRequest = (props) => {
  return (
    <BoxLine to="/api-request">
      <BoxLineText>
        API 요청하기
      </BoxLineText>
      <CaretRight size={16} />
    </BoxLine>
  )
}

const ItemTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const CheckDocs = (props) => {
  return (
    <BoxLineA href='https://www.notion.so/hyunsdev/Hyuns-API-496efc5818c84f01840ded604af72e68' target="_blank" >
      <ItemTitle>
        <File size={16} />
        <BoxLineText>
          API 문서 확인하기
        </BoxLineText>
      </ItemTitle>
      <CaretRight size={16} />
    </BoxLineA>
  )
}

const CheckServer = (props) => {
  return (
    <BoxLineA href='https://www.notion.so/hyunsdev/5c3a18b91c42435ea3500dafb21e64d7' target="_blank" >
      <ItemTitle>
        <File size={16} />
        <BoxLineText>
          서버 목록 관리
        </BoxLineText>
      </ItemTitle>
      <CaretRight size={16} />
    </BoxLineA>
  )
}

function MainScreen() {
  const navigate = useNavigate()
  const [server, setServer] = useState([])
  const [revenue, setRevenue] = useState(0)

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get("https://api.hyuns.dev/server/info", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        setServer(response.data.data)
      } catch (err) {
        console.log(err)
      }

      try {
        const RevenueResponse = await axios.get("https://api.hyuns.dev/var/secret/dashboard/revenue", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        setRevenue(RevenueResponse.data)
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login")
    }
  }, [navigate])

  useEffect(() => {
    return () => setServer([]);
  }, []);

  const logout = () => {
    localStorage.removeItem("token")
    navigate("/login")
  }

  return (
    <Wrapper>
      <Divver>
        <Header>
          <Package weight="bold" size={32} color='#3359FC'/> Hyuns API
        </Header>
        <Revenue>지금까지의 총 수익<br /><span>{revenue.toLocaleString()}₩</span></Revenue>
        <ServerStatus server={server} />
        <ServerMessage server={server} />
        <BoxGrid>
          <VarSecret />
          <VarGlobal />
          <Resource />
          <ApiRequest />
          <CheckDocs />
          <CheckServer />
        </BoxGrid>
        <Logout onClick={logout}>로그아웃</Logout>
      </Divver>
    </Wrapper>
  );
}

export default MainScreen;
