import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

import axios from 'axios';
import { Link } from 'react-router-dom';
import ItemAdd from "../../components/itemAdd"
import Header from "../../components/header"
import Search from "../../components/search"

import { Divver } from '../../components/Divver';

dayjs.locale('ko')



const H1 = styled.h1`
    font-size: 24px;
`

const VarLink = styled.div`
  font-size: 12px;
  width: 100%;
  word-wrap: break-word; 
  color: #9A9EAF;
`

const A = styled.a`
  text-decoration: none;
`

const ResourceAddBtn = styled(Link)`
    text-decoration: none;
`

const ResourceItemDiv = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
`

const ResourceItemName = styled.div`
  
`

const ResourceItemDate = styled.div`
  color: #8B8B8B;
  font-size: 12px;
`

const ResourceItemTitle = styled.div`
  transition: 100ms;
  color: ${props => props.willDelete ? "#ff0000" : props.copied ? "#4AD175" : "#000000"};
`

const ResourceItemBtns = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;
`

const ResourceItemBtn = styled.div`
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  opacity: 0.8;
  border-radius: 8px;
  width: fit-content;
  padding: 4px 16px;
  transition: 200ms;
  cursor: pointer;

  &:active {
    opacity: 1;
  }
`

const ResourceItemImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 12px;
  object-fit: contain;
`

const ResourceItem = (props) => {
  const [willDelete, setWillDelete] = useState(false)
  const [copied, setCopied] = useState(false)

  const deleteItem = async () => {
    if (!willDelete) {
      setWillDelete(true);
      setTimeout(() => {
        setWillDelete(false);
      }, 2000)
    } else {
      try {
        await axios.delete(`https://api.hyuns.dev/resource?path=${props.content}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        props.remove()
      } catch (err) {
        console.log(err)
      }
    }
  }

  const copyItem = async () => {
    props.setSelectedLink(props.content)
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000)
    await navigator.clipboard.writeText(`https://static.hyuns.dev/${props.content}`);
  }

  return (
    <ResourceItemDiv>
      <ResourceItemName>
        <ResourceItemDate>{dayjs(props.label).format("YYYY.MM.DD HH:mm:ss")}</ResourceItemDate>
        <ResourceItemTitle willDelete={willDelete} copied={copied}>{props.content}</ResourceItemTitle>
        <ResourceItemBtns>
          <ResourceItemBtn color='#4AD175' backgroundColor="#E3FFEC" onClick={copyItem}>복사</ResourceItemBtn>
          <ResourceItemBtn color='#FC3333' backgroundColor="#FEECEC" onClick={deleteItem}>삭제</ResourceItemBtn>
        </ResourceItemBtns>
      </ResourceItemName>
      <ResourceItemImg src={`https://static.hyuns.dev/${props.content}`}/>
    </ResourceItemDiv>
  )
}

function Resource() {
  const vars = useRef([])
  const [resource, setResource] = useState([])
  const [searchText, setSearchText] = useState("")
  const [selectedLink, setSelectedLink] = useState("")

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get("https://api.hyuns.dev/resource", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        vars.current = response.data.Contents
        setResource(vars.current)
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  useEffect(() => {
    return () => setResource([]);
  }, []);

  useEffect(() => {
    setResource(vars.current.filter(e => {
      if (e.Key.includes(searchText)) {
        return true
      } else {
        return false
      }
    }))
  }, [searchText])

  const remove = (key) => {
    vars.current = vars.current.filter(e => {
      if (e.Key === key) {
        return false
      } else {
        return true
      }
    })
    setResource(vars.current)
  }

  useEffect(() => {
    return () => setResource([]);
  }, []);

  return (
    <>
        
        <Divver>
        <Header title="리소스" to="/" />
            <H1>리소스</H1>
            {selectedLink && <A href={`https://static.hyuns.dev/${selectedLink}`} target="_blank"><VarLink>https://static.hyuns.dev/{selectedLink}</VarLink></A>}
            <Search value={searchText} onChange={e => setSearchText(e.target.value)}/>
            {resource.map((e,i) => {
                return <ResourceItem key={i} label={e.LastModified} content={e.Key} remove={() => remove(e.Key)} setSelectedLink={setSelectedLink} />
            })}
            <ResourceAddBtn to="/resource/add"><ItemAdd /></ResourceAddBtn>
        </Divver>
    </>
  );
}

export default Resource;
 