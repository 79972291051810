import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    border: 0;
    margin: 0;
    -webkit-tap-highlight-color : transparent;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    line-height: 1.5;
    font-family: 'Noto Sans KR', sans-serif;
    overflow: overlay;
  }
`;

export default GlobalStyle;