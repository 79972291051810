import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Item from "../components/item"
import Header from "../components/header"
import { Divver } from '../components/Divver'

dayjs.locale('ko')

const DayLabel = styled.div`
  font-size: 12px;
  margin-top: 24px;
  margin-bottom: -4px;
  color: #8B8B8B;
`

const H1 = styled.h1`
    font-size: 24px;
    margin-bottom: -16px;
`

function ServerStatus() {
  const servers = useRef()
  const [messages, setMessages] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      try {
        // 서버 리스트 조회
        const serverResponse = await axios.get("https://api.hyuns.dev/server/info", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })

        let serverList = {}
        for (let i of serverResponse.data.data) {
          serverList[i.svId] = {
            img: i.svImg,
            name: i.svName
          }
        }

        servers.current = serverList

        // 메세지 리스트 조회
        const messageResponse = await axios.get("https://api.hyuns.dev/message?limit=1000", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })

        let day = ""
        setMessages(messageResponse.data.data.map((e, i) => {
          let eDay = dayjs(e.msgCreated).format("YYYY.MM.DD dddd")
          let dayLabel
          if (day !== eDay) {
            day = eDay
            dayLabel = <DayLabel>{eDay}</DayLabel>
          }
          return (
            <div key={i}>
              {dayLabel}
              <Item img={servers.current[e.msgAuthorId]?.img} status={e.msgLevel} label={`${dayjs(e.msgCreated).format("HH:mm:ss")}`} content={servers.current[e.msgAuthorId]?.name || "(삭제된 서버)"} message={e.msgContent}/>
            </div>
          )
        }))

      } catch (err) {
        if (err.response) {
          if (err.response.data.message === "wrong_token") {
            localStorage.removeItem("token")
            navigate("/login")
          } else if (err.response.data.message === "server_not_found") {
            navigate("/status")
          } else {
            console.log(err.response)
          }
        }
        console.error(err)
      }
    })()
  }, [servers, navigate])

  useEffect(() => {
    return () => setMessages([]);
  }, []);

  return (
    <>
      <Divver>
      <Header title="Server Message" to="/" />
        <H1>Server Message</H1>
        <div>
          {messages}
        </div>
      </Divver>
    </>
  );
}

export default ServerStatus;
