import { useEffect, useState } from 'react';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import InputText from "../../components/InputText"
import Header from "../../components/header"
import BlueBtn from '../../components/blueBtn';

import { Divver } from '../../components/Divver';

const H1 = styled.h1`
    font-size: 24px;
`

const VarLink = styled.div`
  font-size: 12px;
  width: 100%;
  word-wrap: break-word; 
  color: #DCDCDC;
`

function VarAdd() {
  const [group, setGroup] = useState("")
  const [value, serValue] = useState("")
  const [key, setKey] = useState("")
  const [img, setImg] = useState("")

  const [btnStatus, setBtnStatus] = useState({
    isAble: false,
    text: "변수 정보를 채워주세요."
  })

  const navigate = useNavigate()

  useEffect(() => {
    if (group && value && key) {
      setBtnStatus({
        isAble: true,
        text: `${group}/${key} 변수를 생성합니다.`
      })
    } else {
      setBtnStatus({
        isAble: false,
        text: `변수 정보를 채워주세요.`
      })
    }
  }, [group, value, key, img])

  const varAdd = async () => {
    if (!btnStatus.isAble) return
    try {
        await axios.post("https://api.hyuns.dev/var/secret", {group, value, key, img}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      navigate("/var/secret")
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "wrong_token") {
          localStorage.removeItem("token")
          navigate("/login")
        } else {
          console.log(err.response)
        }
      }

      console.error(err)
    }
  }

  return (
    <>
      
      <Divver>
      <Header title="비밀 변수 추가하기" to="/var/secret" />
        <H1>비밀 변수 추가하기</H1>
        <VarLink>https://api.hyuns.dev/var/secret/{group}/{key}</VarLink>
        <InputText title="그룹" onChange={(e) => setGroup(e.target.value)} value={group}/>
        <InputText title="키" onChange={(e) => setKey(e.target.value)} value={key}/>
        <InputText title="값" onChange={(e) => serValue(e.target.value)} value={value}/>
        <InputText title="이미지" onChange={(e) => setImg(e.target.value)} value={img}/>
        <BlueBtn onClick={varAdd} isAble={btnStatus.isAble} text={btnStatus.text} />
      </Divver>
    </>
  );
}

export default VarAdd;
