import { useEffect, useState } from 'react';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import InputText from "../../components/InputText"
import Header from "../../components/header"
import BlueBtn from '../../components/blueBtn';

import { Divver } from '../../components/Divver';

const H1 = styled.h1`
    font-size: 24px;
`

function ServerAdd() {
  const [id, setId] = useState("")
  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [checkURL, setCheckURL] = useState("")
  const [img, setImg] = useState("")
  const [memo, setMemo] = useState("")
  const [btnStatus, setBtnStatus] = useState({
    isAble: false,
    text: "서버 정보를 채워주세요."
  })

  const navigate = useNavigate()

  useEffect(() => {
    if (id && name && address && checkURL && img) {
      setBtnStatus({
        isAble: true,
        text: `${name} 서버를 생성합니다.`
      })
    } else {
      setBtnStatus({
        isAble: false,
        text: `서버 정보를 채워주세요.`
      })
    }
  }, [id, name, address, checkURL, img])

  const serverAdd = async () => {
    if (!btnStatus.isAble) return
    try {
        await axios.post("https://api.hyuns.dev/server", {
        id: id,
        name: name,
        address: address,
        checkURL: checkURL,
        img: img,
        memo: memo
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      navigate("/status")
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "wrong_token") {
          localStorage.removeItem("token")
          navigate("/login")
        } else {
          console.log(err.response)
        }
      }

      console.error(err)
    }
  }

  return (
    <>
      
      <Divver>
      <Header title="서버 추가하기" to="/status" />
        <H1>서버 추가하기</H1>
        <InputText title="아이디" onChange={(e) => setId(e.target.value)} value={id}/>
        <InputText title="이름" onChange={(e) => setName(e.target.value)} value={name}/>
        <InputText title="주소" onChange={(e) => setAddress(e.target.value)} value={address}/>
        <InputText title="상태 확인 API 주소" label="요청시 200 응답을 해야 합니다." onChange={(e) => setCheckURL(e.target.value)} value={checkURL}/>
        <InputText title="이미지" onChange={(e) => setImg(e.target.value)} value={img}/>
        <InputText title="메모" onChange={(e) => setMemo(e.target.value)} value={memo}/>
        <BlueBtn onClick={serverAdd} isAble={btnStatus.isAble} text={btnStatus.text} />
      </Divver>
    </>
  );
}

export default ServerAdd;
