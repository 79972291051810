import { useEffect } from 'react';
import { useNavigate, Routes, Route  } from 'react-router-dom';

import Main from "./resource/main"
import Add from "./resource/add"

function Resource() {
    const navigate = useNavigate()
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/login")
        }
    }, [navigate])


    return (
        <>
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/add" element={<Add />} />
            </Routes>
        </>
    );
}

export default Resource;
