import { useRef, useState } from "react";
import styled from "styled-components";
import { UploadSimple } from "phosphor-react";

const InputBlock = styled.div`
    font-size: 14px;
    margin-top: 8px;
    
`

const InputTitle = styled.div`
    margin-left: 4px;
    margin-bottom: 4px;
`

const Input = styled.input`
    display: none;
`

const InputBtn = styled.div`
    width: 100%;
    background-color: ${props => props.readOnly ? "#B3B9C4" : "#ECF3FE"};
    padding: 12px;
    border-radius: 8px;
    transition: 200ms;
    outline: solid 2px #00000000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    cursor: pointer;

    &:active{
        ${props => props.readOnly ? "" : "outline: solid 2px #3359FC"};
    }
`

const InputLabel = styled.div`
    color: #3359FC;
    font-size: 12px;
    margin-left: 8px;
`

function InputFile(props) {
    const [fileSelected, setFileSelected] = useState(false)
    const [fileName, setFileName] = useState("파일을 선택하세요")
    const uploadFile = useRef()

    const onChange = (event) => {
        setFileName(event.target.files[0].name)
        props.setUploadFile(event.target.files[0])
        setFileSelected(true)
    }

    const onClick = (event) => {
        event.preventDefault();
        uploadFile.current.click()
    }

    return (
        <InputBlock>
            <InputTitle>{props.title}</InputTitle>
            <Input ref={uploadFile} type="file" id="file" onChange={onChange}></Input>
            <InputBtn onClick={onClick}><UploadSimple size={24} color={fileSelected ? "#3359FC" : "#8B8B8B"} />{fileName}</InputBtn>
            {props.label ? <InputLabel>{props.label}</InputLabel> : <></>}
        </InputBlock>
    )
}

export default InputFile;
