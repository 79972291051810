import styled, { createGlobalStyle } from "styled-components";
import StatusDot from "./StatusDot";
import logo from "../static/logo_circle.png"

const ItemDiv = styled.div`
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    height: 40px;
`

const ItemProfileDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
    flex: 1;
`

const Profile = styled.div`
    position: relative;
`

const ItemImg = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 20px;
`

const ProfileDot = styled.div`
    position: absolute;
    bottom: 0px;
    right: -4px;
`

const Content = styled.div`
    
`

const ContentLabel = styled.div`
    font-size: 12px;
    margin-bottom: -4px;
    color: #C4C4C4;
    width: fit-content;
    white-space: nowrap;
`

const ContentText = styled.div`
    font-size: 16px;
`

const Message = styled.div`
    text-align: right;
    flex: 1;
    width: 100%;
`

function Item(props) {
    return (
        <ItemDiv>
            <ItemProfileDiv>
                <Profile>
                    <ItemImg src={props.img || logo} />
                    {props.status ? <ProfileDot><StatusDot status={props.status} /></ProfileDot> : <></>}
                </Profile>
                <Content>
                    <ContentLabel>{props.label || (new Date()).toISOString().replace("T", " ")}</ContentLabel>
                    <ContentText>{props.content}</ContentText>
                </Content>
            </ItemProfileDiv>
            {props.message && <Message>{props.message}</Message>}
        </ItemDiv>
    )
}

export default Item