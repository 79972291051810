import { BrowserRouter, Routes, Route , useLocation} from "react-router-dom";
import MainScreen from "./screen/Main"
import LoginScreen from "./screen/Login"
import StatusScreen from "./screen/Status"
import MessageScreen from "./screen/Message"
import GlobalVar from "./screen/GlobalVar"
import SecretVar from "./screen/SecretVar"
import Resource from "./screen/Resource"
import ApiRequest from "./screen/apiRequest"
import styled from "styled-components";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import './static/animation.css'
import { useEffect } from "react";

const Divver = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto;
  min-height: 100vh;
`

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
`

function App() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname === '/') {
      document.querySelector('meta[name=theme-color]').content = '#ECEFF2'
    } else {
      document.querySelector('meta[name=theme-color]').content = '#ffffff'
    }
  }, [location.pathname])

  return (
      <Divver>
        <TransitionGroup component={null}>
          <CSSTransition key={location.key} classNames="pageSlider" timeout={300}>
            <Wrapper>
              <Routes>
                <Route path="/" element={<MainScreen />}/>
                <Route path="login" element={<LoginScreen />} />
                <Route path="status/*" element={<StatusScreen />} />
                <Route path="message" element={<MessageScreen />} />
                <Route path="var/global/*" element={<GlobalVar />} />
                <Route path="var/secret/*" element={<SecretVar />} />
                <Route path="resource/*" element={<Resource />} />
                <Route path="api-request/*" element={<ApiRequest />} />
              </Routes>
            </Wrapper>
          </CSSTransition>
        </TransitionGroup>
      </Divver>
  );
}

const Root = () => <BrowserRouter><App /></BrowserRouter>

export default Root;
