import styled from "styled-components";
import { MagnifyingGlass } from "phosphor-react";

const SearchDiv = styled.div`
    width: 100%;
    background-color: #ECF3FE;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    margin-top: 4px;
`

const SearchInput = styled.input`
    background-color: #ECF3FE;
    width: 100%;
    margin-left: 8px;

    &:focus {
        outline: 0;
    }
`

function Search(props) {
    return (
        <SearchDiv>
            <MagnifyingGlass size={24} color="#3359FC" />
            <SearchInput readOnly={props.readOnly} placeholder={props.placeholder || "검색"} onChange={props.onChange} value={props.value}/>
        </SearchDiv>
    )
}

export default Search;
