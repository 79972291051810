import styled from 'styled-components'
import { Link } from 'react-router-dom';
import { ArrowLeft } from "phosphor-react";
import { useState, useEffect } from 'react'

const HeaderDivver = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: ${props => props.isTop ? 'absolute' : 'fixed'};
    backdrop-filter: blur(4px);
    left: 0;
    top: 0;
    z-index: 99999;
`

const HeaderDiv = styled.div`
    width: 100%;
    max-width: 900px;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 12px 4px;
`

const BackLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    padding: 12px;
    color: #000000;
    transition: 200ms;
    backdrop-filter: blur(8px);
    &:hover {
        background-color: #f0f0f0;
    }
    &:active {
        color: #000000;
    }
`

const Title = styled.div`
    margin-left: 8px;
    width: 100%;
`

function Header(props) {
    const [isTop, setIsTop] = useState(true)

    const onScroll = () => {
        setIsTop(window.scrollY === 0)
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    return (
        <HeaderDivver>
            <HeaderDiv isTop={isTop}>
                <BackLink to={props.to} replace={true}><ArrowLeft size={24} weight="bold" color='#1a1c1f' /></BackLink>
                <Title>{props.title}</Title>
            </HeaderDiv>
        </HeaderDivver>
    )
}

  
export default Header;
  