import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import InputText from "../../components/InputText"
import InputFile from "../../components/InputFile"
import Header from "../../components/header"
import BlueBtn from '../../components/blueBtn';

import { Divver } from '../../components/Divver';

const H1 = styled.h1`
    font-size: 24px;
`

function VarAdd() {
  const [path, setGroup] = useState("")
  const [uploadFile, setUploadFile] = useState({})

  const [btnStatus, setBtnStatus] = useState({
    isAble: false,
    text: "리소스 정보를 채워주세요."
  })

  const navigate = useNavigate()

  useEffect(() => {
    if (path && uploadFile.name) {
      setBtnStatus({
        isAble: true,
        text: `${path} 리소스를 생성합니다.`
      })
    } else {
      setBtnStatus({
        isAble: false,
        text: `리소스 정보를 채워주세요.`
      })
    }
  }, [path, uploadFile])

  const resourceUpload = async () => {
    if (!btnStatus.isAble) return
    try {
        setBtnStatus({
          isAble: false,
          text: "업로드..."
        })

        const uploadURLResponse = await axios.post("https://api.hyuns.dev/resource", {
            path,
            fileType: uploadFile.type
          }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })

        console.log(uploadFile.type)

        await axios.put(uploadURLResponse.data.uploadURL, uploadFile, {
          headers: {
            "Content-Type": uploadFile.type
          }
        })
        navigate("/resource")

    } catch (err) {
      setBtnStatus({
        isAble: true,
        text: "업로드에 실패했습니다."
      })

      if (err.response) {
        if (err.response.data.message === "wrong_token") {
          localStorage.removeItem("token")
          navigate("/login")
        } else {
          console.log(err.response)
        }
      }

      console.error(err)
    }
  }

  return (
    <>
      
      <Divver>
      <Header title="리소스 추가하기" to="/resource" />
        <H1>리소스 추가하기</H1>
        <InputText title="파일 이름" onChange={(e) => setGroup(e.target.value)} value={path}/>
        <InputFile title="파일" setUploadFile={setUploadFile}/>
        <BlueBtn onClick={resourceUpload} isAble={btnStatus.isAble} text={btnStatus.text} />
      </Divver>
    </>
  );
}

export default VarAdd;
