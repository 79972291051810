import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components'

import axios from 'axios';
import { Link } from 'react-router-dom';
import Item from "../../components/item"
import ItemAdd from "../../components/itemAdd"
import Header from "../../components/header"
import Search from "../../components/search"

import { Divver } from '../../components/Divver';

const H1 = styled.h1`
    font-size: 24px;
`

const VarAddBtn = styled(Link)`
    text-decoration: none;
`

const VarEditBtn = styled(Link)`
    text-decoration: none;
    color: #000000;
`

function SecretVar() {
  const vars = useRef([])
  const [secretVar, setSecretVar] = useState([])
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get("https://api.hyuns.dev/var/secret", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        vars.current = response.data.data
        setSecretVar(vars.current)

      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  useEffect(() => {
    return () => setSecretVar([]);
  }, []);

  useEffect(() => {
    setSecretVar(vars.current.filter(e => {
      if (e.varGroup.includes(searchText) || e.varKey.includes(searchText)) {
        return true
      } else {
        return false
      }
    }))
  }, [searchText])

  return (
    <>
        
        <Divver>
        <Header title="비밀 변수" to="/" />
            <H1>비밀 변수</H1>
            <Search value={searchText} onChange={e => setSearchText(e.target.value)}/>
            {secretVar.map((e,i) => {
                return <VarEditBtn to={`/var/secret/edit/${e.varGroup}/${e.varKey}`} key={i}><Item img={e.varImg} label={e.varGroup} content={e.varKey} /></VarEditBtn>
            })}
            <VarAddBtn to="/var/secret/add"><ItemAdd /></VarAddBtn>
        </Divver>
    </>
  );
}

export default SecretVar;
 