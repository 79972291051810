import { useEffect, useState } from 'react';
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom';

import axios from 'axios';
import InputText from "../../components/InputText"
import Header from "../../components/header"
import BlueBtn from '../../components/blueBtn';

import { Divver } from '../../components/Divver';

const H1 = styled.h1`
    font-size: 24px;
`

const ApiKey = styled.div`
  font-size: 12px;
  width: 100%;
  word-wrap: break-word; 
  color: #9A9EAF;
`

function ServerEdit() {
  const [id, setId] = useState("")
  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [checkURL, setCheckURL] = useState("")
  const [img, setImg] = useState("")
  const [memo, setMemo] = useState("")

  const [apiKey, setApiKey] = useState("")
  const [btnStatus, setBtnStatus] = useState({
    isAble: false,
    text: "서버 정보를 채워주세요."
  })

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`https://api.hyuns.dev/server/info/${location.pathname.split("/")[3]}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        console.log(response)
        setId(response.data.svId)
        setName(response.data.svName)
        setAddress(response.data.svAddress)
        setCheckURL(response.data.svCheckURL)
        setImg(response.data.svImg)
        setMemo(response.data.svMemo || "")
        setApiKey(response.data.svApiKey)
      } catch (err) {
        if (err.response) {
          if (err.response.data.message === "wrong_token") {
            localStorage.removeItem("token")
            navigate("/login")
          } else if (err.response.data.message === "server_not_found") {
            navigate("/status")
          } else {
            console.log(err.response)
          }
        }
        console.error(err)
      }
    })()
  }, [location.pathname, navigate])

  useEffect(() => {
    if (address && checkURL && img) {
      if (name !== "") {
        setBtnStatus({
          isAble: true,
          text: `${name} 서버를 수정합니다.`
        })
      } else {
        setBtnStatus({
          isAble: true,
          text: `서버를 삭제합니다.`
        })
      }
    } else {
      setBtnStatus({
        isAble: false,
        text: `서버 정보를 채워주세요.`
      })
    }
  }, [name, address, checkURL, img])

  const serverEdit = async () => {
    if (!btnStatus.isAble) return
    try {
      if (name !== "") {
        await axios.patch(`https://api.hyuns.dev/server/${location.pathname.split("/")[3]}`, {
          name: name,
          address: address,
          checkURL: checkURL,
          img: img,
          memo: memo
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
      } else {
        await axios.delete(`https://api.hyuns.dev/server/${location.pathname.split("/")[3]}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
      }

      navigate("/status")
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "wrong_token") {
          localStorage.removeItem("token")
          navigate("/login")
        } else {
          console.log(err.response)
        }
      }
      console.error(err)
    }
  }

  return (
    <>
      
      <Divver>
      <Header title="서버 편집하기" to="/status" />
        <H1>서버 편집하기</H1>
        <ApiKey>{apiKey}</ApiKey>
        <InputText title="아이디" readOnly={true} value={id}/>
        <InputText label="이름을 빈칸으로 두는 경우 서버가 삭제됩니다." title="이름" onChange={(e) => setName(e.target.value)} value={name}/>
        <InputText title="주소" onChange={(e) => setAddress(e.target.value)} value={address}/>
        <InputText title="상태 확인 API 주소" label="요청시 200 응답을 해야 합니다." onChange={(e) => setCheckURL(e.target.value)} value={checkURL}/>
        <InputText title="이미지" onChange={(e) => setImg(e.target.value)} value={img}/>
        <InputText title="메모" onChange={(e) => setMemo(e.target.value)} value={memo}/>
        <BlueBtn onClick={serverEdit} isAble={btnStatus.isAble} text={btnStatus.text} />
      </Divver>
    </>
  );
}

export default ServerEdit;
