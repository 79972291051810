import styled from "styled-components";

const BtnDivver = styled.div`
    z-index: 99999;
    position: fixed;
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 24px;
    bottom: 0;
    left: 0;
`

const Btn = styled.div`
    z-index: 99999;
    padding: 16px;
    width: 100%;
    max-width: 900px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.isAble ? "#3359FC" : "#CACDD6"};
    border-radius: 14px; 
    color: #ffffff;
    cursor: ${props => props.isAble ? "pointer" : "default"};
    user-select: none;
    -webkit-tap-highlight-color : transparent;

    &:active {
        background-color: ${props => props.isAble ? "#274DEE" : "#CACDD6"};
    }
`

function BlueBtn(props) {
    return (
        <BtnDivver>
            <Btn isAble={props.isAble} onClick={props.onClick}>
                {props.text}
            </Btn>
        </BtnDivver>
    )
}

export default BlueBtn;
