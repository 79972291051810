import { useEffect, useState } from 'react';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import InputText from "../../components/InputText"
import Header from "../../components/header"
import BlueBtn from '../../components/blueBtn';

import { Divver } from '../../components/Divver';

const H1 = styled.h1`
    font-size: 24px;
`

function VarAdd() {
  const [name, setName] = useState("")
  const [path, setPath] = useState("")
  const [method, setMethod] = useState("")
  const [body, setBody] = useState("")
  const [header, setHeader] = useState("")
  const [img, setImg] = useState("")
  const [memo, setMemo] = useState("")

  const [btnStatus, setBtnStatus] = useState({
    isAble: false,
    text: "요청 정보를 채워주세요."
  })

  const navigate = useNavigate()

  useEffect(() => {
    if (name && path && method) {
      setBtnStatus({
        isAble: true,
        text: `${name} 요쳥을 저장합니다.`
      })
    } else {
      setBtnStatus({
        isAble: false,
        text: `변수 정보를 채워주세요.`
      })
    }
  }, [name, path, method])

  const Add = async () => {
    if (!btnStatus.isAble) return
    try {
        await axios.post("https://api.hyuns.dev/api-request", {name, path, method, body, header, img, memo}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      navigate("/api-request")
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "wrong_token") {
          localStorage.removeItem("token")
          navigate("/login")
        } else {
          console.log(err.response)
        }
      }

      console.error(err)
    }
  }

  return (
    <Divver>
      <Header title="API 요청 추가하기" to="/api-request" />
      <H1>API 요청 추가하기</H1>
      <InputText title="이름" onChange={(e) => setName(e.target.value)} value={name}/>
      <InputText title="주소" onChange={(e) => setPath(e.target.value)} value={path}/>
      <InputText title="메소드" onChange={(e) => setMethod(e.target.value)} value={method}/>
      <InputText title="Body" onChange={(e) => setBody(e.target.value)} value={body}/>
      <InputText title="header" onChange={(e) => setHeader(e.target.value)} value={header}/>
      <InputText title="이미지" onChange={(e) => setImg(e.target.value)} value={img}/>
      <InputText title="메모" onChange={(e) => setMemo(e.target.value)} value={memo}/>
      <BlueBtn onClick={Add} isAble={btnStatus.isAble} text={btnStatus.text} />
    </Divver>
  );
}

export default VarAdd;
