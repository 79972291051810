import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes, Route } from "react-router-dom";

import Main from "./secretVar/main"
import Add from "./secretVar/add"
import Edit from "./secretVar/edit"

function Var() {
    const navigate = useNavigate()
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/login")
        }
    }, [navigate])

    return (
        <>
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/add" element={<Add />} />
                <Route path="/edit/*" element={<Edit />} />
            </Routes>
        </>
    );
}

export default Var;
