import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom';

import axios from 'axios';
import InputText from "../../components/InputText"
import Header from "../../components/header"
import BlueBtn from '../../components/blueBtn';

import { Divver } from '../../components/Divver';

const H1 = styled.h1`
    font-size: 24px;
`

const APIOutput = styled.pre`
  width: 100%;
  background-color: #ECF3FE;
  padding: 12px;
  border-radius: 8px;
  transition: 200ms;
  outline: solid 2px #00000000;
  margin-top: 24px;
`

function VarEdit() {
  const [name, setName] = useState("")
  const [path, setPath] = useState("")
  const [method, setMethod] = useState("")
  const [body, setBody] = useState("")
  const [header, setHeader] = useState("")
  const [img, setImg] = useState("")
  const [memo, setMemo] = useState("")

  const [isChanged, setChanged] = useState(false)
  const [response, setResponse] = useState("")

  const [btnStatus, setBtnStatus] = useState({
    isAble: false,
    text: "변수 정보를 채워주세요."
  })

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    (async () => {
      try {
        const pathname = location.pathname.split("/")
        const response = await axios.get(`https://api.hyuns.dev/api-request/${pathname[3]}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        setName(response.data.name)
        setPath(response.data.path)
        setMethod(response.data.method)
        setBody(response.data.body)
        setHeader(response.data.header)
        setImg(response.data.img)
        setMemo(response.data.memo)
        setChanged(false)
      } catch (err) {
        if (err.response) {
          if (err.response.data.message === "wrong_token") {
            localStorage.removeItem("token")
            navigate("/login")
          } else if (err.response.data.message === "server_not_found") {
            navigate("/status")
          } else {
            console.log(err.response)
          }
        }
        console.error(err)
      }
    })()
  }, [])

  useEffect(() => {
    if (name !== "") {
      if (isChanged) {
        setBtnStatus({
          isAble: true,
          text: `업데이트`
        })
      } else {
        setBtnStatus({
          isAble: true,
          text: `${name} 요청을 수행합니다.`
        })
      }
    } else {
      setBtnStatus({
        isAble: true,
        text: `요청를 삭제합니다.`
      })
    }
  }, [name, isChanged])

  useEffect(() => {
    setChanged(true)
  }, [name, path, method, body, header, img, memo])

  const edit = async () => {
    if (!btnStatus.isAble) return
    try {
      const pathname = location.pathname.split("/")
      if (name !== "") {
        if (isChanged) {
          await axios.patch(`https://api.hyuns.dev/api-request/${pathname[3]}`, {
            name, path, method, body, header, img, memo
          }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          setChanged(false)
        } else {
          try {
            const res = await axios.post(`https://api.hyuns.dev/api-request/${pathname[3]}`, {}, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
              }
            })
            console.log(res.data)
            setResponse(<>{res.data.status}<br />{JSON.stringify(res.data.data, null, 2)}</>)
            setBtnStatus({
              isAble: true,
              text: `${name} 요청이 ${res.data.status}으로 응답했습니다.`
            })
          } catch (err) {
            if (err.response) {
              if (err.response.data.message === "wrong_token") {
                localStorage.removeItem("token")
                navigate("/login")
              } else {
                console.log(err.response)
              }
            }
      
            console.error(err)
          }

        }
      } else {
        await axios.delete(`https://api.hyuns.dev/api-request/${pathname[3]}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        navigate("/api-request")
      }
      
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "wrong_token") {
          localStorage.removeItem("token")
          navigate("/login")
        } else {
          console.log(err.response)
        }
      }
      console.error(err)
    }
  }

  return (

    <Divver>
    <Header title="API 요청" to="/api-request" />
      <H1>API 요청</H1>
      <InputText title="이름" onChange={(e) => setName(e.target.value)} value={name} label="값을 비우면 요청을 삭제합니다."/>
      <InputText title="주소" onChange={(e) => setPath(e.target.value)} value={path}/>
      <InputText title="메소드" onChange={(e) => setMethod(e.target.value)} value={method}/>
      <InputText title="Body" onChange={(e) => setBody(e.target.value)} value={body || ""}/>
      <InputText title="header" onChange={(e) => setHeader(e.target.value)} value={header || ""}/>
      <InputText title="이미지" onChange={(e) => setImg(e.target.value)} value={img || ""}/>
      <InputText title="메모" onChange={(e) => setMemo(e.target.value)} value={memo || ""}/>
      <APIOutput>{response || ""}</APIOutput>
      {/* <InputText readOnly={true} title="응답" value={response || ""}/> */}
      <BlueBtn onClick={edit} isAble={btnStatus.isAble} text={btnStatus.text} />
    </Divver>
  );
}

export default VarEdit;
