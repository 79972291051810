import { useEffect, useState } from 'react';
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom';

import axios from 'axios';
import InputText from "../../components/InputText"
import Header from "../../components/header"
import BlueBtn from '../../components/blueBtn';

import { Divver } from '../../components/Divver';

const H1 = styled.h1`
    font-size: 24px;
`

const VarLink = styled.div`
  font-size: 12px;
  width: 100%;
  word-wrap: break-word; 
  color: #9A9EAF;
`

const A = styled.a`
  text-decoration: none;
`

function VarEdit() {
  const [group, setGroup] = useState("")
  const [value, serValue] = useState("")
  const [key, setKey] = useState("")
  const [img, setImg] = useState("")

  const [btnStatus, setBtnStatus] = useState({
    isAble: false,
    text: "변수 정보를 채워주세요."
  })

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    (async () => {
      try {
        const pathname = location.pathname.split("/")
        const response = await axios.get(`https://api.hyuns.dev/var/global/${pathname[4]}/${pathname[5]}?info=true`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        setGroup(response.data.varGroup)
        serValue(response.data.varValue)
        setKey(response.data.varKey)
        setImg(response.data.varImg)
      } catch (err) {
        if (err.response) {
          if (err.response.data.message === "wrong_token") {
            localStorage.removeItem("token")
            navigate("/login")
          } else if (err.response.data.message === "server_not_found") {
            navigate("/status")
          } else {
            console.log(err.response)
          }
        }
        console.error(err)
      }
    })()
  }, [])

  useEffect(() => {
    if (value !== "") {
      setBtnStatus({
        isAble: true,
        text: `${group}/${key} 변수를 수정합니다.`
      })
    } else {
      setBtnStatus({
        isAble: true,
        text: `변수를 삭제합니다.`
      })
    }
  }, [value, img])

  const varEdit = async () => {
    if (!btnStatus.isAble) return
    try {
      const pathname = location.pathname.split("/")
      if (value !== "") {
        await axios.patch(`https://api.hyuns.dev/var/global/${pathname[4]}/${pathname[5]}`, {
          value, img
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
      } else {
        await axios.delete(`https://api.hyuns.dev/var/global/${pathname[4]}/${pathname[5]}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
      }
      navigate("/var/global")
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "wrong_token") {
          localStorage.removeItem("token")
          navigate("/login")
        } else {
          console.log(err.response)
        }
      }
      console.error(err)
    }
  }

  return (
    <>
      
      <Divver>
      <Header title="공개 변수 편집하기" to="/var/global" />
        <H1>공개 변수 편집하기</H1>
        <A href={`https://api.hyuns.dev/var/global/${group}/${key}`} target="_blank"><VarLink>https://api.hyuns.dev/var/global/{group}/{key}</VarLink></A>
        <InputText readOnly={true} title="그룹" onChange={(e) => setGroup(e.target.value)} value={group}/>
        <InputText readOnly={true} title="키" onChange={(e) => setKey(e.target.value)} value={key}/>
        <InputText label="값을 비우면 변수를 삭제합니다." title="값" onChange={(e) => serValue(e.target.value)} value={value}/>
        <InputText title="이미지" onChange={(e) => setImg(e.target.value)} value={img}/>
        <BlueBtn onClick={varEdit} isAble={btnStatus.isAble} text={btnStatus.text} />
      </Divver>
    </>
  );
}

export default VarEdit;
