import styled from "styled-components";

const Wrapper1 = styled.div`
    background-color: #ffffff;
    width: 100%;
`

const Wrapper2 = styled.div`
    background-color: #ffffff;
    padding: 20px;
    min-height: 100%;
    padding-bottom: 120px;
    min-height: 100vh;
    padding-top: 52px;
    max-width: 900px;
    margin: 0 auto;
`

export const Divver = (props) => {
    return (
        <Wrapper1>
            <Wrapper2>
                {props.children}
            </Wrapper2>
        </Wrapper1>
    )
}
