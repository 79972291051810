import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'

import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Item from "../../components/item"
import ItemAdd from "../../components/itemAdd"
import Header from "../../components/header"
import Search from "../../components/search"

import { Divver } from '../../components/Divver';

const H1 = styled.h1`
    font-size: 24px;
`

const VarAddBtn = styled(Link)`
    text-decoration: none;
`

const ApiRequest = styled(Link)`
    text-decoration: none;
    color: #000000;
`

function ApiRequests() {
  const vars = useRef([])
  const [globalVar, setGlobalVar] = useState([])
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get("https://api.hyuns.dev/api-request", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        vars.current = response.data.data
        setGlobalVar(vars.current)
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  useEffect(() => {
    return () => setGlobalVar([]);
  }, []);

  useEffect(() => {
    setGlobalVar(vars.current.filter(e => {
      if (e.varGroup.includes(searchText) || e.varKey.includes(searchText)) {
        return true
      } else {
        return false
      }
    }))
  }, [searchText])

  return (
        
        <Divver>
          <Header title="API 요청" to="/" />
          <H1>API 요청</H1>
          <Search value={searchText} onChange={e => setSearchText(e.target.value)}/>
            {globalVar.map((e,i) => {
                return <ApiRequest to={`/api-request/edit/${e._id}`} key={i}><Item img={e.img} label={e.path} content={e.name} /></ApiRequest>
            })}
          <VarAddBtn to="/api-request/add"><ItemAdd /></VarAddBtn>
        </Divver>
  );
}

export default ApiRequests;
 