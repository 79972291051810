import { useEffect, useState } from 'react';
import styled from 'styled-components'
import BlueBtn from '../components/blueBtn';
import logo from "../static/logo_circle.png"
import InputText from '../components/InputText';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Divver = styled.div`
  padding: 20px;
`

const LogoImg = styled.img`
  width: 42px;
  margin-top: 20px;
`

const Title = styled.h1`
  font-size: 24px;
  margin-top: 8px;
`

function LoginScreen() {
  const [id, setId] = useState("")
  const [password, setPassword] = useState("")
  const [isAbleContinue, setAbleContinue] = useState(false)
  const [label, setLabel] = useState({id: "", password: ""})
  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/")
    }
  }, [navigate])

  useEffect(() => {
    setAbleContinue(id && password)
  }, [id, password])

  const login = async (e) => {
    if (!isAbleContinue) return

    try {
      setLabel({id: "", password: ""})
      const response = await axios.post(`https://api.hyuns.dev/auth/login`, {
        id, password
      })
      localStorage.setItem("token", response.data.token)
      navigate("/")
    } catch (err) {
      if (err.response) {
        switch (err.response.data.message) {
          case "user_not_found":
            setLabel({id: "계정이 없습니다", password: ""})
            break

          case "wrong_password":
            setLabel({id: "", password: "올바르지 않은 비밀번호입니다"})
            break

          default:
            break
        }
      } else {
        console.log(err)
        setLabel({id: "서버에 연결할 수 없습니다.", password: ""})
      }
    }
  }

  return (
    <Divver>
      <LogoImg src={logo} />
      <Title>Hyuns API에 연결하고<br />다양한 서비스를 사용해보세요</Title>
      <form>
        <InputText label={label.id} value={id} title="아이디" onChange={(e) => setId(e.target.value)} placeholder="아이디를 입력하세요"/>
        <InputText label={label.password} type="password" value={password} title="비밀번호" onChange={(e) => setPassword(e.target.value)} placeholder="비밀번호를 입력하세요" />
      </form>
      <BlueBtn text={isAbleContinue ? "로그인" : "정보를 입력하세요"} isAble={isAbleContinue} onClick={login} />
    </Divver>
  );
}

export default LoginScreen;
