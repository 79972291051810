import styled from 'styled-components'


const StatusDotDiv = styled.div`
width: 18px;
height: 18px;
border-radius: 9px;
background-color: ${props => props.color};
border: solid 2px #ffffff;
`

function StatusDot(props) {
    let color
    switch (props.status) {
        case "good":
        case "info":
            color = "#4AD175"
            break
        
        case "warn":
            color = "#E8CC3E"
            break

        case "error":
        case "bad":
            color = "#FC3333"
            break

        default:
            color = "#CACDD1"

    }

    return (
        <StatusDotDiv color={color} />
    )
}

  
export default StatusDot;
  