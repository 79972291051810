import styled from "styled-components";

const InputBlock = styled.div`
    font-size: 14px;
    margin-top: 8px;
    
`

const InputTitle = styled.div`
    margin-left: 4px;
    margin-bottom: 4px;
`

const Input = styled.input`
    width: 100%;
    background-color: ${props => props.readOnly ? "#B3B9C4" : "#ECF3FE"};
    padding: 12px;
    border-radius: 8px;
    transition: 200ms;
    outline: solid 2px #00000000;

    &:focus{
        ${props => props.readOnly ? "" : "outline: solid 2px #3359FC"};
    }
`

const InputLabel = styled.div`
    color: #3359FC;
    font-size: 12px;
    margin-left: 8px;
`

function InputText(props) {
    return (
        <InputBlock>
            <InputTitle>{props.title}</InputTitle>
            <Input readOnly={props.readOnly} type={props.type || "text"} autoComplete="on" placeholder={props.placeholder} onChange={props.onChange} value={props.value}></Input>
            {props.label ? <InputLabel>{props.label}</InputLabel> : <></>}
        </InputBlock>
    )
}

export default InputText;
