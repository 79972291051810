import { useEffect, useState } from 'react';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { Link } from 'react-router-dom';
import Item from "../../components/item"
import ItemAdd from "../../components/itemAdd"
import Header from "../../components/header"

import { Divver } from '../../components/Divver';

const StatusBox = styled.div`
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    background-color: ${props => props.color};
    color: #ffffff;
    text-align: center;
    cursor: pointer;
`

const ServerAddBtn = styled(Link)`
    text-decoration: none;
`

const ServerEditBtn = styled(Link)`
    text-decoration: none;
    color: #000000;
`

function ServerStatus() {
  const [server, setServer] = useState([])
  const [serverStatus, setServerStatus] = useState({message: "잠시 기다려주세요", color: "#CACDD1", lastCheck: " "})

  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get("https://api.hyuns.dev/server/info", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        setServer(response.data.data)

        let isOK = true
        for (let i of response.data.data) {
            if (i.svStatus !== "good") isOK = false
        }

        setServerStatus(isOK ? {color: "#4AD175", message: "서버가 모두 정상이에요", lastCheck: response.data.lastCheck} : {color: "#FC3333", message: "서버에 문제가 발생했어요", lastCheck: response.data.lastCheck})
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  useEffect(() => {
    return () => setServer([]);
  }, []);

  const check = async () => {
    try {
      setServerStatus({message: "서버 상태를 확인하고 있어요.", color: "#DCDCDC", lastCheck: " "})
      const response = await axios.post("https://api.hyuns.dev/server/check", {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      setServer(response.data.data)

      let isOK = true
      for (let i of server) {
          if (i.svStatus !== "good") isOK = false
      }

      setServerStatus(isOK ? {color: "#4AD175", message: "서버가 모두 정상이에요", lastCheck: response.data.lastCheck} : {color: "#FC3333", message: "서버에 문제가 발생했어요", lastCheck: response.data.lastCheck})
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "wrong_token") {
          localStorage.removeItem("token")
          navigate("/login")
        } else if (err.response.data.message === "server_not_found") {
          navigate("/status")
        } else {
          console.log(err.response)
        }
      }
      console.error(err.response)
      setServerStatus({message: "서버 상태를 가져올 수 없어요.", color: "#ff0000", lastCheck: " "})
    }
  }

  return (
    <>
        <Divver>
            <Header title="Server Status" to="/" />
            <StatusBox onClick={check} color={serverStatus.color}>{serverStatus.message}<br />{serverStatus.lastCheck}</StatusBox>
            {server.map((e,i) => {
                return <ServerEditBtn to={`/status/edit/${e.svId}`} key={i}><Item img={e.svImg} status={e.svStatus} label={e.svId} content={e.svName} /></ServerEditBtn>
            })}
            <ServerAddBtn to="/status/add"><ItemAdd /></ServerAddBtn>
        </Divver>
    </>
  );
}

export default ServerStatus;
 